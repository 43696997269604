@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --toastify-toast-min-height: 56px !important;
  --toastify-toast-width: fit-content !important;
}
* {
  overflow-wrap: break-word;
}

html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

@media only screen and (max-width: 576px) {
  html {
    scroll-snap-type: none;
    scroll-behavior: auto;
  }
  .scrollbar-custom::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}

.Toastify__toast {
  border-radius: 16px !important;
  box-shadow:
    0px 0px 0px 0px rgba(95, 104, 123, 0.1),
    0px 5px 10px 0px rgba(95, 104, 123, 0.09),
    0px 19px 19px 0px rgba(95, 104, 123, 0.08),
    0px 42px 25px 0px rgba(95, 104, 123, 0.05),
    0px 74px 30px 0px rgba(95, 104, 123, 0.01),
    0px 116px 32px 0px rgba(95, 104, 123, 0) !important;
  font-family: 'Sora' !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 150% !important;
  color: black !important;
  min-width: 260px !important;
}
.Toastify__toast-icon {
  width: 32px !important;
}

.toast-earn-aig {
  background-color: #ebf4fe !important;
  border-radius: 100px !important;
  height: 64px !important;
  min-width: 0 !important;
  .Toastify__toast-body {
    margin: 0 !important;
    display: block !important;
    padding: 0 !important;
    align-self: center !important;
  }
}

span svg polyline {
  color: #da613a;
  stroke-width: 4;
}
.after\:bg-primary::after {
  background-color: #f8dfd8 !important;
}
span.w-5.h-5.relative.inline-flex.items-center.justify-center.flex-shrink-0 {
  width: 24px;
  height: 24px;
}

.tooltip {
  border-radius: 16px !important;
  padding: 10px 12px !important;
  background-color: rgba(4, 13, 46) !important;
}
.arrow-tooltip {
  background-color: rgba(4, 13, 46) !important;
}
.light-tooltip {
  border-radius: 20px !important;
  border: 1px solid #f0f2fa !important;
  background: #fff !important;
  box-shadow: 0px 8px 25px 5px rgba(199, 205, 231, 0.25) !important;
  padding: 0 !important;
}

.no-hover:hover {
  background-color: #515adc !important;
  color: #ffffff !important;
}

.input-hidden-arrow input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.input-hidden-arrow input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.dropdown-hidden-selected span.text-inherit.w-3.h-3.flex-shrink-0 {
  display: none;
}

.image-canvas > canvas {
  width: 100% !important;
  height: 100% !important;
}

@layer base {
  body {
    font-family: 'Sora';
    color: '#181818';
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .main-container {
    max-width: 1480px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .black-backdrop {
    z-index: 50;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
  }

  .scrollbar-custom::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .scrollbar-w-small::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  .scrollbar-custom::-webkit-scrollbar-track {
    background: transparent;
  }
  .scrollbar-custom::-webkit-scrollbar-thumb {
    background: #dee1eb;
    border-radius: 5px;
  }
  .scrollbar-custom-dark::-webkit-scrollbar-thumb {
    background: #505665;
  }

  input[type='range'] {
    height: 6px;
    border-radius: 4px;
    accent-color: #e18161;
    border: none;
    outline: none;
  }

  .box-content-header-mobile {
    background: #fff;
    box-shadow: 0px 8px 25px 5px rgba(199, 205, 231, 0.25);
  }

  .btn-upgrade-plan {
    background: linear-gradient(54deg, #f8dfd8 0%, #f0c0b0 100%) !important;
  }

  .shadow-2 {
    box-shadow: 0px 8px 25px 5px rgba(199, 205, 231, 0.25) !important;
  }

  .shadow-scroll {
    box-shadow: 10px 0px 16px 8px rgba(199, 205, 231, 0.44);
  }

  .shadow-card-transparent {
    box-shadow: 0px 4px 8px 4px rgba(199, 205, 231, 0.25);
  }

  .shadow-card {
    box-shadow: 0px 4px 8px 4px rgba(199, 205, 231, 0.25);
    background: radial-gradient(59.71% 59.71% at 50% 42.81%, #000 0%, rgba(0, 0, 0, 0) 100%);
  }

  .card-shadow-3 {
    box-shadow: 4px 6px 16px 8px rgba(199, 205, 231, 0.44);
  }

  .bg-coming-soon-campaign {
    background: radial-gradient(72.16% 72.15% at 50% 50%, #7a3c3c 0%, rgba(34, 9, 1, 0.2) 100%);
  }

  .reset-input-range {
    -webkit-appearance: none;
    outline: none;
    box-shadow: none;
  }

  .bg-campaign-detail {
    background: linear-gradient(
      181deg,
      rgba(43, 45, 40, 0.3) 1.03%,
      rgba(18, 18, 17, 0.76) 68.46%,
      #050505 106.72%
    );
  }

  .bg-image-campaign {
    flex-shrink: 0;
    background: radial-gradient(59.71% 59.71% at 50% 42.81%, #000 0%, rgba(0, 0, 0, 0) 100%);
  }

  .bg-generating {
    border-radius: 8px;
    border: 1px solid var(--border, #f0f2fa);
    background: var(
      --liner,
      linear-gradient(267deg, #db8fff -8.45%, #ff62ab 49.67%, #ffb090 102.02%)
    );
  }

  .paint-input-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    border: 1px solid #dee1eb;
  }
}

@layer utilities {
  .subpixel-antialiased {
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
  }
  .antialiased {
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
  }
  .animate-fade-in-up {
    animation: fade-in-up 4s ease-in-out;
  }
  .animate-fade-in {
    animation: fade-in 0.7s ease-in-out forwards;
  }
  .animate-fade-in-transform-left {
    animation: fade-in-transform-left 0.7s ease-in-out forwards;
  }
  .animate-fade-in-transform-right {
    animation: fade-in-transform-right 0.7s ease-in-out forwards;
  }
  .group[data-focus-visible='true'] .group-data-\[focus-visible\=true\]\:ring-2 {
    box-shadow: none !important;
  }
  .animate-delay-05 {
    animation-delay: 0.5s;
  }
  .animate-delay-1 {
    animation-delay: 1s;
  }
  .animate-delay-15 {
    animation-delay: 1.5s;
  }
}

.text-svg {
  animation: animate-text-svg 1s ease-in-out forwards;
}

a {
  @apply text-black;
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-transform-left {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes fade-in-transform-right {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes animate-text-svg {
  0% {
    width: 0;
  }
  100% {
    width: 383px;
  }
}

@font-face {
  font-family: 'klapt';
  src: url('../../public/fonts/Klapt-Light.otf') format('opentype');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'klapt';
  src: url('../../public/fonts/Klapt-Medium.otf') format('opentype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'klapt';
  src: url('../../public/fonts/Klapt-Regular.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'klapt';
  src: url('../../public/fonts/Klapt-Bold.otf') format('opentype');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Sora';
  src: url('../../public/fonts/Sora-Thin.ttf') format('opentype');
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: 'Sora';
  src: url('../../public/fonts/Sora-Light.ttf') format('opentype');
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: 'Sora';
  src: url('../../public/fonts/Sora-ExtraLight.ttf') format('opentype');
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'Sora';
  src: url('../../public/fonts/Sora-Regular.ttf') format('opentype');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Sora';
  src: url('../../public/fonts/Sora-Medium.ttf') format('opentype');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'Sora';
  src: url('../../public/fonts/Sora-SemiBold.ttf') format('opentype');
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'Sora';
  src: url('../../public/fonts/Sora-Bold.ttf') format('opentype');
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Sora';
  src: url('../../public/fonts/Sora-ExtraBold.ttf') format('opentype');
  font-weight: 800;
  font-display: swap;
}
